import { useCallback, useContext } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { matchPathsByType } from '../constants/Path.utils.ts'
import { KioskContext } from '../context/KioskContextProvider'
import { ThemeContext } from '../context/ThemeContextProvider'
import RoutePath from '../structures/Enums/RoutePath.enum.ts'

function useNavigationBehaviour() {
    const location = useLocation()
    const navigate = useNavigate()
    const { theme } = useContext(ThemeContext)
    const { kioskMode } = useContext(KioskContext)

    const previousLocation = location?.state?.from
    const mutatePreviousLocation = useCallback(
        (mutation) => {
            if (location.state) {
                location.state.from = mutation
            } else {
                location.state = { from: mutation }
            }
        },
        [location?.state]
    )

    const onBackNavigation = useCallback(() => {
        const pathType = matchPathsByType(location.pathname)
        let pathToReturnTo = previousLocation || -1

        switch (pathType) {
            case 'event':
                if (theme) {
                    pathToReturnTo = theme.venueScreenDisabled ? theme.placePath ?? RoutePath.HOMEPAGE : theme.venuePath
                }
                // for kiosk we go to the /events route with an events overview
                if (kioskMode) pathToReturnTo = RoutePath.EVENTS
                break

            case 'purchase':
                if (theme) {
                    pathToReturnTo = theme.venueScreenDisabled ? theme.placePath ?? RoutePath.HOMEPAGE : theme.venuePath
                }
                break

            case 'eventGroupEvent':
                const eventGroupEventPath = location.pathname
                const eventGroupPath = eventGroupEventPath.slice(0, eventGroupEventPath.lastIndexOf('/'))
                if (matchPathsByType(eventGroupPath) === 'eventGroup') {
                    pathToReturnTo = eventGroupPath
                }
                break

            case 'eventGroup':
                pathToReturnTo = RoutePath.HOMEPAGE
                break

            case 'venue':
                pathToReturnTo = theme.placePath || RoutePath.HOMEPAGE
                break

            case 'place':
                pathToReturnTo = RoutePath.HOMEPAGE
                break

            case 'categoryGroup':
                pathToReturnTo = location.pathname.slice(0, location.pathname.lastIndexOf('/g'))
                break

            case 'category':
                pathToReturnTo = location.pathname.slice(0, location.pathname.lastIndexOf('/c'))
                break

            default:
                pathToReturnTo = previousLocation || -1
                break
        }

        navigate(pathToReturnTo, {
            state: { ...location.state },
        })
    }, [matchPathsByType, location?.pathname, navigate])

    return {
        previousLocation,
        onBackNavigation,
        mutatePreviousLocation,
    }
}

export default useNavigationBehaviour
